import Text from "@/client/components/atoms/text"
import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "@/client/components/atoms/ui/tabs"
import { CrossIcon } from "@/client/components/assets"
import Profile from "@/client/components/molecules/profile"
import { PlanView } from "@/client/components/molecules/payment-plan"
import { UserBO } from "@/server/features/user/bo"

type TabLayoutProp = {
	onClose: React.MouseEventHandler<HTMLButtonElement>
	title: string
	account: UserBO | undefined
	isLoading: boolean
	tabType: "MyPLan" | "Profile"
}

const TABS = [
	{
		name: "Profile",
		value: "profile",
		Content: Profile,
	},
	{
		name: "My Plan",
		value: "my_plan",
		Content: PlanView,
	},
]

const AccountTabLayout = ({
	onClose,
	title,
	account,
	isLoading,
	tabType,
}: TabLayoutProp) => {
	const activeTab = tabType === "Profile" ? TABS[0].value : TABS[1].value
	return (
		<div className="flex flex-col">
			<div className="sticky top-0">
				<div className="flex justify-between bg-neutral-100 border lg:px-12 lg:py-6 px-6 py-3">
					<Text variant="text-semibold">{title}</Text>
					<button onClick={onClose}>
						<CrossIcon />
					</button>
				</div>
			</div>

			<Tabs
				defaultValue={activeTab}
				className="w-full h-full flex lg:flex-row flex-col">
				<TabsList className="w-full grid-cols-2 lg:w-[240px] h-auto flex lg:flex-col flex-row gap-x-2 bg-neutral-100 border-r-[1.5px] border-neutral-200 justify-start content-start p-2 lg:p-5 rounded-none">
					{TABS.map(tab => (
						<TabsTrigger
							className="w-full data-[state=active]:bg-[#f0f0f0] bg-transparent justify-start my-2 lg:px-7 py-3 px-4 rounded-md"
							value={tab.value}
							key={`tab-trigger-${tab.value}`}>
							{tab.name}
						</TabsTrigger>
					))}
				</TabsList>
				{TABS.map((tab, index) => (
					<TabsContent
						value={tab.value}
						className="w-full my-0"
						key={`tab-content-${index}`}>
						<tab.Content account={account} isLoading={isLoading} />
					</TabsContent>
				))}
			</Tabs>
		</div>
	)
}

export default AccountTabLayout
