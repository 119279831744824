import ProfileView from "./view"
import EditProfile from "./edit"

import React from 'react'
import { useAccount } from "@/client/store/account"

const Profile = () => {
    const { account, isEditing, setIsEditing } = useAccount()

    return (
        <>
            {!isEditing ? <ProfileView onEdit={() => setIsEditing(true)} />
                : <>{account ? <EditProfile /> : null}</>
            }
        </>
    )
}

export default Profile
