"use client"

import React, { useEffect } from "react"
import {
	Dialog,
	DialogContent,
	DialogPortal,
	DialogOverlay,
} from "@/client/components/atoms/ui/dialog"
import AccountTabLayout from "@/client/components/molecules/account-tab-layout"
import { useAccount } from "@/client/store/account"
import { useAccountSettingsModal } from "@/client/store/account-settings"

const AccountSettings = () => {
	const { account, getAccount, loading, setIsEditing } = useAccount()
	const accountSettings = useAccountSettingsModal()

	useEffect(() => {
		if (!accountSettings.open) {
			setTimeout(() => {
				document.body.style.pointerEvents = ""
			}, 500)
			setIsEditing(false)
		} else {
			getAccount()
		}
	}, [accountSettings.open])

	return (
		<Dialog open={accountSettings.open} onOpenChange={accountSettings.setOpen}>
			<DialogPortal>
				<DialogOverlay />
				<DialogContent className="min-h-[70vh] max-w-10/12 xl:w-10/12 lg:w-10/12 overflow-y-scroll max-h-[75vh] md:max-h-[90vh]">
					<AccountTabLayout
						onClose={() => accountSettings.setOpen(false)}
						title="Account Settings"
						account={account}
						isLoading={loading}
						tabType={accountSettings.tabType}
					/>
				</DialogContent>
			</DialogPortal>
		</Dialog>
	)
}

export default AccountSettings
