import React from 'react'
import {
	TabsList,
	TabsTrigger,
} from "@/client/components/atoms/ui/tabs"

type TabGroupProp = {
	values: string[]
    tabGroupKey: string
}

// to be used within <Tabs> context
const TabGroup = ({ values, tabGroupKey }: TabGroupProp) => (
	<TabsList className="ml-auto p-0 items-start h-min">
		{values.map(value => (
			<TabsTrigger
				value={value} // prefixing to make it unique across the app
				key={`${tabGroupKey}-${value}`}
				className="text-neutral-300 border border-zinc-300 data-[state=active]:text-primary data-[state=active]:border-primary rounded-none first:rounded-l last:rounded-r">
				{tabName(value)}
			</TabsTrigger>
		))}
	</TabsList>
)

function tabName(value: string) {
	if(value.toLowerCase() === "year") {
		return "Annually"
	} else {
		return value[0].toUpperCase() + value.slice(1).toLowerCase() + "ly"
	}
}

export default TabGroup
