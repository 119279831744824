"use client"

import React, { useState } from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/client/components/atoms/ui/form"
import { Input } from "@/client/components/atoms/ui/input"
import { Button } from "@/client/components/atoms/ui/button"
import Text from "@/client/components/atoms/text"
import { Typography } from "@/client/components/atoms/heading"
import { RadioGroup } from "@/client/components/atoms/ui/radio-group"
import {
	data as investor_types,
	DEFAULT_INVESTOR_TYPES,
	INVESTOR_TYPE_OTHER,
} from "@/client/store/local/onboarding"
import { data as market_types } from "@/client/components/molecules/onboarding-cards/onboarding-market-type"
import RadioButton from "@/client/components/atoms/radio-button"
import { ICountry } from "@/client/constants/constants"
import { OnboardingCountrySelectorType } from "@/client/lib/types/onboarding-types"
import { AccountSchema, useAccount } from "@/client/store/account"
import { UserCountryType } from "@/client/lib/interface"

const FormSchema = z.object({
	first_name: AccountSchema.FirstNameSchema,
	last_name: AccountSchema.LastNameSchema,
	investor_type: AccountSchema.InvestorTypeSchema,
	preferred_country: AccountSchema.PreferredCountrySchema,
})

const EditProfile = () => {
	const { loading, error, resetErrors, account, updateUserAccount } =
		useAccount()
	const [isInvestorTypeOther, setInvestorTypeOther] = useState<boolean>(
		account?.investor_type
			? !DEFAULT_INVESTOR_TYPES.includes(account.investor_type)
			: false
	)

	const form = useForm<z.infer<typeof FormSchema>>({
		resolver: zodResolver(FormSchema),
		defaultValues: {
			first_name: account?.first_name,
			last_name: account?.last_name,
			preferred_country:
				account?.preferred_country.toUpperCase() as UserCountryType,
			investor_type: DEFAULT_INVESTOR_TYPES.includes(
				account?.investor_type as string
			)
				? {
						investor_type: account?.investor_type,
						investor_type_other: "",
				  }
				: {
						investor_type: INVESTOR_TYPE_OTHER,
						investor_type_other: account?.investor_type,
				  },
		},
	})

	const onSelect = () => {
		form.watch(value => {
			if (error.status) {
				resetErrors()
			}
			if (value?.investor_type?.investor_type === INVESTOR_TYPE_OTHER) {
				setInvestorTypeOther(true)
			} else if (value?.investor_type?.investor_type) {
				setInvestorTypeOther(false)
			}
		})
	}

	async function onSubmit(formData: z.infer<typeof FormSchema>) {
		if (!loading) {
			await updateUserAccount({
				first_name: formData.first_name,
				last_name: formData.last_name,
				preferred_country: formData.preferred_country as ICountry,
				investor_type: isInvestorTypeOther
					? formData.investor_type.investor_type_other
					: formData.investor_type.investor_type,
			})
		}
	}

	return (
		<div className="mb-4">
			<div className="flex justify-between items-center border border-neutral-200 lg:px-12 lg:py-5 px-6 py-3">
				<div>
					<Text variant="text-semibold" className="hidden lg:flex text-sm">
						Edit Profile
					</Text>
				</div>
			</div>

			<Form {...form}>
				<form
					onSubmit={form.handleSubmit(onSubmit)}
					className="w-full max-w-[460px] space-y-6 pt-4 lg:px-12 px-6">
					<FormField
						control={form.control}
						name="first_name"
						render={({ field }) => (
							<FormItem className="flex flex-col gap-2.5">
								<FormLabelWrapper>First Name</FormLabelWrapper>
								<FormControl>
									<Input
										key="user"
										placeholder="Enter your first name"
										{...field}
										className="border placeholder:text-zinc-200 placeholder:text-base focus:border-neutral-300 h-[60px] text-medium/10 focus-visible:ring-0 focus:ring-0 mb-2"
									/>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="last_name"
						render={({ field }) => (
							<FormItem className="flex flex-col gap-2.5">
								<FormLabelWrapper>Last Name</FormLabelWrapper>
								<FormControl>
									<Input
										key="user"
										placeholder="Enter your last name"
										{...field}
										className="border placeholder:text-zinc-200 placeholder:text-base focus:border-neutral-300 h-[60px] text-medium/10 focus-visible:ring-0 focus:ring-0 mb-2"
									/>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="investor_type.investor_type"
						render={({ field }) => (
							<FormItem className="flex flex-col gap-2.5">
								<FormLabelWrapper>Investor Type</FormLabelWrapper>
								<FormControl>
									<RadioGroup
										onValueChange={field.onChange}
										className="flex flex-col space-y-1 focus:border-neutral-300">
										{investor_types.radioFields &&
											investor_types.radioFields.map(
												(
													item: OnboardingCountrySelectorType,
													index: number
												) => (
													<RadioButton
														key={`market-type-${index}`}
														checked={field.value === item.radioOption}
														value={item.radioOption}
														onSelect={onSelect}>
														<Typography size="sm">
															{item.radioOption}
														</Typography>
													</RadioButton>
												)
											)}
									</RadioGroup>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>

					{isInvestorTypeOther ? (
						<FormField
							control={form.control}
							name="investor_type.investor_type_other"
							render={({ field }) => (
								<FormItem className="flex flex-col gap-2.5">
									<FormLabelWrapper>{INVESTOR_TYPE_OTHER}</FormLabelWrapper>
									<FormControl>
										<Input
											placeholder="Type your specific investor role"
											{...field}
											className="bg-white border-solid border placeholder:text-zinc-200 placeholder:text-base focus:border-neutral-300 h-[60px] text-medium/10 focus-visible:ring-0 focus:ring-0 mb-2"
										/>
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
					) : null}

					<FormField
						control={form.control}
						name="preferred_country"
						render={({ field }) => (
							<FormItem className="flex flex-col gap-2.5">
								<FormLabelWrapper>Market</FormLabelWrapper>
								<FormControl>
									<RadioGroup
										onValueChange={field.onChange}
										className="flex flex-col space-y-1 focus:border-neutral-300 mb-2">
										{market_types.radioFields.map(
											(item: OnboardingCountrySelectorType, index) => (
												<RadioButton
													key={`market-type-${index}`}
													checked={field.value === item.value}
													value={item.value}
													onSelect={() => {}}>
													<>
														<div className="flex items-center gap-1">
															<Typography size="sm">{item.country}</Typography>
															{item.logo}
														</div>
														<Typography
															variant="text"
															size="xs"
															className="flex gap-1">
															{item.exchanges.map((exchange, index) => {
																return (
																	<div key={`exchange_${index}`}>
																		<span className="flex">
																			{exchange.toUpperCase()}
																			{index < item.exchanges.length - 1
																				? ","
																				: " "}
																		</span>
																	</div>
																)
															})}
														</Typography>
													</>
												</RadioButton>
											)
										)}
									</RadioGroup>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<Button type="submit" variant="onboarding" className="flex gap-2">
						Submit
					</Button>
					{!loading && error.status ? (
						<p className="text-sm font-medium text-destructive">
							{error.message}
						</p>
					) : null}
				</form>
			</Form>
		</div>
	)
}

export default EditProfile

const FormLabelWrapper = ({ children }: { children: React.ReactNode }) => (
	<FormLabel className="flex flex-col gap-y-2.5 justify-start">
		<div className="text-neutral-300 text-sm font-normal my-2">{children}</div>
	</FormLabel>
)
