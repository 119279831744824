"use client"

import { createContext, useContext, useEffect, useState } from "react"
import { useRouter } from "next/navigation"
import { createSupabaseFrontendClient } from "@/common/auth/supabase/client"
import { SupabaseClient } from "@supabase/supabase-js"

type SupabaseContext = {
	supabase: SupabaseClient
}

const Context = createContext<SupabaseContext | undefined>(undefined)

export default function SupabaseProvider({
	children,
}: {
	children: React.ReactNode
}) {
	const [supabase] = useState(() => createSupabaseFrontendClient())
	const router = useRouter()

	useEffect(() => {
		const {
			data: { subscription },
		} = supabase.auth.onAuthStateChange(() => {
			router.refresh()
		})

		return () => {
			subscription.unsubscribe()
		}
	}, [router, supabase])

	return (
		<Context.Provider value={{ supabase }}>
			<>{children}</>
		</Context.Provider>
	)
}

export const useSupabase = () => {
	const context = useContext(Context)

	if (context === undefined) {
		throw new Error("useSupabase must be used inside SupabaseProvider")
	}

	return context
}
