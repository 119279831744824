"use client"

import React, { createContext, useEffect } from "react"
import { useAccount } from "../account"
import { useParams } from "next/navigation"
import { useRouter } from "next/navigation"
import { ROUTES } from "@/client/constants/constants"
import { CountryCode } from "@/common/config"

// type PreferredCountryState = {
// 	open: boolean
// 	tabType: "Profile" | "MyPLan"
// 	setOpen: (open: boolean) => void
// 	setTabType: (tabType: "Profile" | "MyPLan") => void
// }

// export const PreferredCountryContext = createContext<
// 	PreferredCountryState | undefined
// >(undefined)

const PreferredCountryContext = createContext(null)

const PreferredCountryProvider = ({
	children,
}: {
	children: React.ReactNode
}) => {
	const params = useParams()
	const router = useRouter()
	const currentCountry = params.countryCode
	const { account } = useAccount()
	useEffect(() => {
		if (currentCountry && account && account.preferred_country !== currentCountry as string) {
			router.push(ROUTES.DASHBOARD(account?.preferred_country as CountryCode))
		}
	}, [account?.preferred_country, currentCountry])

	return (
		<PreferredCountryContext.Provider value={null}>
			{children}
		</PreferredCountryContext.Provider>
	)

	// const currentCountry = window.location.pathname.split("/")[1]

	// const [open, setModelOpen] = useState(false)
	// const [tabType, setType] = useState<"Profile" | "MyPLan">("Profile")

	// const DEFAULT_TAB = "Profile"

	// const setOpen = (open: boolean) => {
	// 	if (open) setModelOpen(open)
	// 	else {
	// 		setModelOpen(false)
	// 		setTabType(DEFAULT_TAB)
	// 		if (account?.preferred_country !== currentCountry) {
	// 			router.push(`/${account?.preferred_country.toLowerCase()}/filings`)
	// 		}
	// 	}
	// }

	// const setTabType = (tabType: "Profile" | "MyPLan") => setType(tabType)

	// const values = {
	// 	open: open,
	// 	setTabType: setTabType,
	// 	setOpen: setOpen,
	// 	tabType: tabType,
	// }

	// return (
	// 	<PreferredCountryContext.Provider value={values}>
	// 		{children}
	// 	</PreferredCountryContext.Provider>
	// )
}

export default PreferredCountryProvider
