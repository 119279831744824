import React from "react"
import { PricingCardTemplate } from "."
import {
	OneTimePlanDetails,
	SubscriptionPlanDetails,
	UserCountryType,
} from "@/client/lib/interface"
import { PRICING_CONTENT } from "@/client/constants"
import Text from "@/client/components/atoms/text"
import { formatDate } from "@/client/lib/helpers"
import {
	DiscountDetailsBO,
	DiscountType,
	PlanBO,
} from "@/server/features/user/bo"

const StatusChips = {
	ONE_TIME_ACTIVE: ({ date }: { date: string }) => (
		<div className="flex flex-col border rounded border-neutral-200 px-3 pt-0 pb-3 h-fit">
			<Text variant="text-semibold" className="text-primary text-xs leading-8">
				Active
			</Text>
			<Text variant="text-light" className="text-xs">
				Plan ends on {date}
			</Text>
		</div>
	),
	SUBSCRIPTION_ACTIVE: ({ date }: { date: string }) => (
		<div className="flex flex-col justify-center border rounded border-neutral-200 px-3 pt-0 pb-3 h-fit">
			<Text variant="text-semibold" className="text-primary text-xs leading-8">
				Active
			</Text>
			<Text variant="text-light" className="text-xs">
				Renews on {date}
			</Text>
		</div>
	),
	SUBSCRIPTION_CANCELLED: ({ date }: { date: string }) => (
		<div className="flex flex-col justify-center">
			<Text variant="text-semibold" className="text-xs leading-8">
				Cancelled
			</Text>
			<Text variant="text-light" className="text-xs">
				Plan ends on {date}
			</Text>
		</div>
	),
}

const PlanStatus = ({ purchasedPlan }: { purchasedPlan: PlanBO }) => {
	if (purchasedPlan.plan_mode == "ONE_TIME") {
		if (purchasedPlan.plan_details?.status == "active") {
			return (
				<StatusChips.ONE_TIME_ACTIVE
					date={formatDate(
						(purchasedPlan.plan_details as unknown as OneTimePlanDetails)
							?.plan_end_date
					)}
				/>
			)
		}
	} else if (purchasedPlan.plan_mode == "SUBSCRIPTION") {
		if (
			purchasedPlan.plan_details?.status == "active" &&
			(purchasedPlan.plan_details as unknown as SubscriptionPlanDetails)
				?.cancel_at_period_end == true
		) {
			return (
				<StatusChips.SUBSCRIPTION_CANCELLED
					date={formatDate(
						(purchasedPlan.plan_details as unknown as SubscriptionPlanDetails)
							?.current_period_end
					)}
				/>
			)
		}
		if (purchasedPlan.plan_details?.status == "active") {
			return (
				<StatusChips.SUBSCRIPTION_ACTIVE
					date={formatDate(
						(purchasedPlan.plan_details as unknown as SubscriptionPlanDetails)
							?.current_period_end
					)}
				/>
			)
		}
	}
}

const PurchasedPlan = ({
	countryCode,
	plan,
	buttonText,
	onViewPlanDetails,
	discountDetails,
}: {
	countryCode: UserCountryType
	plan: PlanBO
	buttonText?: string
	onViewPlanDetails: () => void
	discountDetails?: DiscountDetailsBO
}) => {
	const planContent = PRICING_CONTENT[countryCode]["PRO_PAID"]

	let infoText = ""
	let isDisabled = false
	if (plan.current_plan_price != plan.plan_price) {
		if (plan.plan_mode == "ONE_TIME") {
			infoText =
				"You're on our old pricing plan. We've increased our prices since March '24"
		} else if (discountDetails?.discount_type !== DiscountType.REFERRAL) {
			infoText =
				"You're on our early adopter plan. Your low price is valid for life-time till you cancel."
		}
	}
	if (plan?.plan_details?.status == "active" && plan.plan_mode == "ONE_TIME") {
		buttonText = "Current Plan"
		isDisabled = true
	}

	return (
		<div>
			<PricingCardTemplate
				buttonText={buttonText || planContent.buttonText}
				isDisabled={isDisabled}
				title={planContent.title}
				description={planContent.description}
				onClick={onViewPlanDetails}
				plans={[plan]}
				countryCode={countryCode}
				planState={<PlanStatus purchasedPlan={plan} />}
				discountDetails={discountDetails}
			/>
			{!infoText ? null : (
				<div
					className="flex items-center my-4 p-4 text-sm text-zinc-400 border-neutral-200 border-[1.5px] rounded-lg bg-gray-50"
					role="alert">
					<svg
						className="flex-shrink-0 inline w-4 h-4 me-3"
						aria-hidden="true"
						xmlns="http://www.w3.org/2000/svg"
						fill="currentColor"
						viewBox="0 0 20 20">
						<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
					</svg>
					<span className="sr-only">Info</span>
					<Text variant="text-light">{infoText}</Text>
				</div>
			)}
		</div>
	)
}

export default PurchasedPlan
