"use client"

import { createContext, useEffect } from "react"

import { useAccount } from "@/client/store/account"

const UserContext = createContext(null)

const UserProvider = () => {
	const { getAccount } = useAccount()

	useEffect(() => {
		getAccount()
	}, [])

	return <UserContext.Provider value={null} />
}

export default UserProvider
