import { create } from "zustand"

type AccountSettingsState = {
	open: boolean
	tabType: "Profile" | "MyPLan"
}

type AccountSettingsActions = {
	setOpen: (open: boolean) => void
	setTabType: (tabType: "Profile" | "MyPLan") => void
	openProfile: () => void
	openMyPlan: () => void
}

const DEFAULT_TAB = "Profile"

export const useAccountSettingsModal = create<
	AccountSettingsState & AccountSettingsActions
>(set => ({
	open: false,
	tabType: "Profile",
	setOpen: open => {
		if (open) set({ open })
		else {
			set({ open: false, tabType: DEFAULT_TAB })
		}
	},
	setTabType: (tabType: "Profile" | "MyPLan") => set({ tabType }),
	openProfile: () => set({ open: true, tabType: "Profile" }),
	openMyPlan: () => set({ open: true, tabType: "MyPLan" }),
}))
