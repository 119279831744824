import { Info } from "lucide-react"
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "../atoms/ui/tooltip"

const InfoTooltip = (props: Props) => {
	const { content, className } = props
	return (
		<TooltipProvider delayDuration={0}>
			<Tooltip>
				<TooltipTrigger className={className} asChild>
					<Info className="w-4 h-4 text-slate-700" />
				</TooltipTrigger>
				<TooltipContent>
					<p>{content}</p>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	)
}

type Props = {
	className?: string
	content: string
}

export default InfoTooltip
