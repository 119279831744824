import React from 'react'
import Button from '@/client/components/atoms/button'
import Text from "@/client/components/atoms/text"
import { EditIcon } from "@/client/components/assets"
import { Skeleton } from '../../atoms/ui/skeleton'
import { useAccount } from '@/client/store/account'

const ProfileView = ({
	onEdit
}: {
	onEdit: () => void
}) => {
	const { account, loading, error } = useAccount()

	if (account?.first_name == "" && account?.last_name == "" && account.name != "") {
		const names = account.name.split(" ")
		account.first_name = names[0]
		names.shift()
		account.last_name = names.join(" ")
	}

	return (
		<>
			<div className="flex justify-between items-center border border-neutral-200 lg:px-12 lg:py-5 px-6 py-3">
				<div>
					<Text variant="text-semibold" className="hidden lg:flex text-sm">
						Profile
					</Text>
				</div>
				<div className="font-medium">
					{
						loading && !error.status ? null : (
							<Button variant="InsightsButton" className="rounded" onClick={onEdit}>
								<div className="flex flow-row px-2.5 py-1 items-center space-x-2.5">
									<EditIcon />
									<div>Edit</div>
								</div>
							</Button>
						)
					}
				</div>
			</div>

			{
				error.status ?
					<p className="m-4">{error.message}</p> : (
						<>
							<UserProperty
								title="First Name"
								value={account?.first_name}
								loading={loading}
							/>
							<UserProperty
								title="Last Name"
								value={account?.last_name}
								loading={loading}
							/>
							<UserProperty
								title="Investor Type"
								value={account?.investor_type}
								loading={loading}
							/>
							<UserProperty
								title="Market"
								value={account?.preferred_country?.toUpperCase()}
								loading={loading}
							/>
						</>
					)
			}

		</>
	)
}

export default ProfileView

const UserProperty = ({
	title,
	value = "",
	loading,
}: {
	title: string
	value: string | undefined
	loading: boolean
}) => {
	if (loading) {
		return (
			<div className="lg:px-12 lg:py-5 px-6 py-3">
				<Skeleton className="mt-2 w-[85px] h-[12px]" />
				<Skeleton className="mt-2 w-[160px] h-[12px]" />
			</div>
		)
	} else {
		return (
			<div className="lg:px-12 lg:py-5 px-6 py-3">
				<div className="text-neutral-300 text-sm">{title}</div>
				<div className="text-neutral-400 font-medium text-sm mt-1">{value || "--"}</div>
			</div>
		)
	}
}
