"use client"
import { useAccount } from "@/client/store/account"
import * as Sentry from "@sentry/nextjs"
import { useEffect } from "react"

export const SentryEnrichedAnalytics = () => {
	const { account } = useAccount()
	useEffect(() => {
		if (account) {
			Sentry.setUser({
				email: account.email,
				username: account.first_name + " " + account.last_name,
				id: account.id,
			})
		} else {
			Sentry.setUser(null)
		}
	}, [account])
	return <></>
}
