import React from "react"
import { PricingCardTemplate } from "."
import { PRICING_CONTENT } from "@/client/constants"
import { UserCountryType } from "@/client/lib/interface"
import { PlanBO } from "@/server/features/user/bo"

const FREE_PLAN_DETAILS = {
	plan_price: 0,
	plan_period: "MONTH",
	current_plan_price: 0,
	plan_code: "",
} as PlanBO

const FreePlan = ({ countryCode }: { countryCode: UserCountryType }) => {
	const planContent = PRICING_CONTENT[countryCode]["FREE"]

	return (
		<PricingCardTemplate
			buttonText="Current Plan"
			isDisabled={true}
			title={planContent.title}
			description={planContent.description}
			onClick={() => {}}
			countryCode={countryCode}
			plans={[FREE_PLAN_DETAILS]}
			planTitle="Free"
		/>
	)
}

export default FreePlan
