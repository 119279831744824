"use client"
import axios from "@/config/axios.config"
import { create } from "zustand"
import { UserCountryType } from "@/client/lib/interface"
import {
	initializePaddle,
	InitializePaddleOptions,
	Paddle,
} from "@paddle/paddle-js"
import { useEffect, useState } from "react"
import { persist, createJSONStorage } from "zustand/middleware"
import { loadStripe } from "@stripe/stripe-js"

type Error = {
	status: boolean
	message?: string
}

type PaymentStoreProps = {
	loading: boolean
	error: Error
	checkout: (data: CheckoutPayloadProp) => Promise<boolean | string>
	redirectToCustomerPortal: (
		data: SubscriptionManagementPayloadProp
	) => Promise<boolean | string>
}

const LOADING_DEFAULT = false
const ERROR_DEFAULT: Error = { status: false, message: undefined }

type CheckoutPayloadProp = {
	country_code: UserCountryType
	plan_code: string
}

type SubscriptionManagementPayloadProp = {
	country_code: UserCountryType
}

function formatPaymentData(data: CheckoutPayloadProp) {
	return {
		country_code: data.country_code.toUpperCase(),
		plan_code: data.plan_code,
	}
}

export type TransactionProp = {
	plan: null | string
	email: string | undefined
	setPlan: (plan: string, email: string | undefined) => void
	clear: () => void
}

export const useTransaction = create(
	persist(
		set => ({
			plan: null,
			email: null,
			setPlan: (plan: string, email: string | undefined) =>
				set({ plan, email }),
			clear: () => set({ plan: null, email: null }),
		}),
		{
			name: "transaction", // name of the item in the storage (must be unique)
			storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
		}
	)
)

export const usePayment = create<PaymentStoreProps>(set => ({
	loading: LOADING_DEFAULT,
	error: ERROR_DEFAULT,
	checkout: async (data: CheckoutPayloadProp) => {
		try {
			set({ loading: true, error: ERROR_DEFAULT })

			const response = await axios.post(
				`/api/payments/checkout`,
				formatPaymentData(data)
			)

			if (response.status != 200) {
				set({
					loading: false,
					error: { status: true, message: "Failed to initiate payment" },
				})
				return false
			}
			// console.log("Checkout Response", response)
			// return response.data?.data?.price_id as string

			// Stripe Checkout
			if (response.data && response.data.data.session_id) {
				// Use the Stripe library to redirect to the checkout
				const stripe = await loadStripe(
					process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY || ""
				)
				stripe
					?.redirectToCheckout({ sessionId: response.data.data.session_id })
					.then(result => {
						if (result.error) {
							set({
								loading: false,
								error: { status: true, message: "Failed to complete payment" },
							})
							console.error(
								"Error in the stripe response",
								result.error.message
							)
						} else {
							set({ loading: false, error: { status: false, message: "" } })
							return true
						}
					})
			} else {
				console.error("Session ID not found in response")
				set({
					error: { status: true, message: "Failed to initiate payment" },
					loading: false,
				})
			}
		} catch (error) {
			console.log(error)
			set({
				error: { status: true, message: "Failed to initiate payment" },
				loading: false,
			})
		}
		return false
	},
	redirectToCustomerPortal: async (data: SubscriptionManagementPayloadProp) => {
		// subscription management
		try {
			set({ loading: true, error: ERROR_DEFAULT })
			const response = await axios.post(
				`/api/payments/customer-portal-session`,
				{
					country_code: data.country_code.toUpperCase(),
				}
			)

			if (response.status == 200) {
				set({ loading: false })
				// Contains mix of paddle and stripe
				if (
					response.data?.data?.session_url ||
					response.data?.data?.cancel_url
				) {
					set({ error: { status: false, message: "" }, loading: false })

					return response.data.data.session_url
						? response.data.data.session_url
						: response.data.data.cancel_url
				} else {
					set({
						loading: false,
						error: { status: true, message: "Failed to load purchases" },
					})
				}
			} else {
				set({
					loading: false,
					error: { status: true, message: "Failed to load purchases" },
				})
			}
		} catch (error) {
			console.log(error)
			set({
				error: { status: true, message: "Failed to load purchases" },
				loading: false,
			})
		}
		return false
	},
}))

export function usePaddle() {
	const [paddle, setPaddle] = useState<Paddle>()
	useEffect(() => {
		initializePaddle({
			environment: process.env.NEXT_PUBLIC_PADDLE_ENV || "sandbox",
			token: process.env.NEXT_PUBLIC_PADDLE_CLIENT_TOKEN!,
			checkout: {
				settings: {
					displayMode: "overlay",
					theme: "light",
					locale: "en",
					frameTarget: "checkout-container",
					frameInitialHeight: "450",
					frameStyle:
						"width: 100%; min-width: 312px; background-color: transparent; border: none;",
				},
			},
			eventCallback: (data: unknown) => {
				console.log(data)
			},
		} as unknown as InitializePaddleOptions).then(
			(paddleInstance: Paddle | undefined) => {
				if (paddleInstance) {
					setPaddle(paddleInstance)
				}
			}
		)
	}, [])

	return paddle
}
