import { UserRole } from "@/common/utils/user-utils"
import { PaymentChannel } from "@/server/external/payment-gateway/types"

export enum DiscountType {
    REFERRAL = "REFERRAL",
    EARLY_ADOPTER = "EARLY_ADOPTER"
}

export type DiscountDetailsBO = {
    discount_type: DiscountType
    discount_percent?: number
    // discount_expiry_date?: Date
}


export type UserBO = {
    id: string
    email: string
    name: string,
    first_name: string,
    last_name: string,
    preferred_country: string,
    investor_type: string,
    current_plans?: { [countryCode: string]: PlanBO } // Only active pro plans. If not, element is absent
    previous_plans?: { [countryCode: string]: PlanBO } // Only expired pro plans. If not, element is absent
    roles?: { [countryCode: string]: UserRole } // Current Active Role.
    upgrade_plans?: { [countryCode: string]: (PlanBO | null)[] | null } // All available plans for upgrade based on the current active plan
    referral_details?: {
        referrer_id: string
    },
    discount_details?: DiscountDetailsBO
}


export enum PaymentMode {
    SUBSCRIPTION = "SUBSCRIPTION",
    ONE_TIME = "ONE_TIME",
}

export enum PlanType {
    FREE = "FREE",
    PRO = "PRO",
}

export enum PlanPeriod {
    MONTH = "MONTH",
    QUARTER = "QUARTER",
    YEAR = "YEAR",
    UNKNOWN = "UNKNOWN",
}

export type OneTimePlanDetails = {
    plan_start_date: Date
    plan_end_date: Date,
    status: string,
}

export type SubscriptionPlanDetails = {
    id: string,
    status: string,
    current_period_start?: Date,
    current_period_end?: Date,
    cancel_at_period_end?: boolean,
}

export type PlanBO = {
    plan_code: string,
    plan_type: PlanType,
    plan_period: PlanPeriod,
    plan_mode: PaymentMode,
    plan_details?: SubscriptionPlanDetails | OneTimePlanDetails,
    plan_price: number,// Actual price for the user. Discounted/Lifetime prices
    current_plan_price: number, // Current price of the the monthly/yearly plan for a normal user
    payment_channel: PaymentChannel
}