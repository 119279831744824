"use client"
import { useAccount } from "@/client/store/account"
import { useEffect } from "react"
import { clarity } from "react-microsoft-clarity"

const MicrosoftClarity = () => {
	const { account } = useAccount()
	useEffect(() => {
		if (!clarity.hasStarted()) {
			clarity.init(process.env.NEXT_PUBLIC_MICROSOFT_CLARITY || "")
		}
		if (clarity.hasStarted() && account) {
			clarity.identify(account.email, {})
			clarity.setTag("country-code", account.preferred_country)
		}
	}, [account])
	return <></>
}

export default MicrosoftClarity
